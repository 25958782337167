main {
  background-color: white;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.7;
}

.grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2.4rem;
  margin: 0px auto;
}

@media screen and (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
