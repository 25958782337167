@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

body {
  background-color: #eee;
}

a {
  color: #4F90CD;
  text-decoration: none;
  font-weight: normal;
}

p {
  margin-top: 20px;
  margin-bottom: 20px;
}

.container-small {
  max-width: 800px;
  margin: 0px auto;
  padding: 40px 20px;
}

.container-medium {
  max-width: 1280px;
  margin: 0px auto;
  padding: 2.4rem;
}


/* Currently disabled */

.filter {
  float: left;
  width: 20%;
  background-color: green;
  margin-right: 15px;
  margin-top: 15px;
}

@media only screen and (max-width: 768px) {
    .filter {
      display: none;
    }
}
