/* Cell */
.cell {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */

  min-width: 300px;
  max-width: 550px;
  height: 550px;

  padding: 20px;

  background-color: white;
  border: 1px solid #ccc;

  position: relative;
}

.featured {
  border: 2px solid #999;
}

@media only screen and (max-width: 768px) {
    .cell {
      min-width: 100%;
      max-width: 100%;
      margin: 10px 0px
    }
}

/* .cell:hover {
  box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.1), 0 0px 0px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #999;
  -webkit-transform: translate(0px, -1px);
      -ms-transform: translate(0px, -1px);
          transform: translate(0px, -1px);
} */

/* CellImage */
.cell-image {
  position: relative;
  height: 180px;
  overflow: hidden;
  margin: -20px;
  margin-bottom: 0px;
  background-color: #eee;
}

.cell-image img {
  position: absolute;
  height: auto;
  width: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}

/* CellHeader */
.cell-header {
  color: #333;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.cell-info {
  font-size: 12px;
  margin-top: auto;
  margin-bottom: auto;
}

.cell-info i {
  margin-left: 10px;
}

/* CellBody */
.cell-body {
  margin-top: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  //height: 130px;
  border-top: 1px solid #ccc;
  padding-top: 10px;

  font-family: Helvetica;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  color: grey;
}

/* CellFooter */
.cell-footer {
  padding: 20px 0px;
  position: absolute;
  bottom: 0;
}

.cell-footer h6 {
  color: grey;
  margin-bottom: 10px;
  margin-top: 15px;
}

.cell-links {
  margin-top: -10px;
}

.cell-link {
  color: #4F90CD;
  font-weight: bold;
  font-size: 12px;
}

.cell-badges {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
}

.cell-badge {
  padding: 5px 7px;
  margin: 0px 5px 5px 0px;
  font-weight: bold;
  border-radius: 5px;
  border: 2px solid #4F90CD;
  color: #4F90CD;
  font-size: 10px;
}
